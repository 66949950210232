export const drawerWidth = 240;

export const rolesType = {
  admin: 'admin',
  sampler: 'sampler',
  technician: 'technician',
  patient: 'patient',
};

export const urls = {
  about: { name: 'About', path: '/about' },
  dashboard: { name: 'Dashboard', path: '/dashboard' },
  exams: { name: 'Exams', path: '/exams' },
  import: { name: 'Import', path: '/import' },
  companies: { name: 'Companies', path: '/companies' },
  root: { name: 'Root', path: '/' },
  logout: { name: 'Logout', path: '/logout' },
  privacy: { name: 'Privacy', path: '/privacy' },
  profile: { name: 'Profile', path: '/profile' },
  recover: { name: 'Recover Password', path: '/recover' },
  signin: { name: 'SignIn', path: '/signin' },
  signup: { name: 'SignUp', path: '/signup' },
  users: { name: 'Users', path: '/users' },
};
