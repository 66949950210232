import { createMuiTheme } from '@material-ui/core';

const palette = {
  primary: {
    main: '#00BCD4',
    darker: '#008BA3',
  },
  custom: {
    mineShaft: '#333333',
  },
};

export default createMuiTheme({
  palette,
  overrides: {
    MuiButton: {
      root: {
        color: palette.primary.darker,
        fontSize: 14,
        fontWeight: '500',
      },
      outlined: {
        borderColor: palette.primary.darker,
        borderWidth: 1,
        borderRadius: 2,
      },
    },
  },
});
