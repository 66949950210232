import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleClose() {
    const { onResult } = this.props;
    onResult('close');
  }

  handleCancel() {
    const { onResult } = this.props;
    onResult('cancel');
  }

  handleConfirm() {
    const { onResult } = this.props;
    onResult('ok');
  }

  render() {
    const { open, title, description } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertModal.propTypes = {
  open: PropTypes.bool,
  onResult: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

AlertModal.defaultProps = {
  open: false,
  title: '',
  description: '',
};

export default AlertModal;
