import firebase from 'firebase';
import requests from './requests';

const removeUndefined = (obj) => Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));

export const convertStructure = (data) => {
  const {
    id, name, customId, requesterName, crm, requesterEmail, cnpj,
  } = data;
  const newStructure = {
    id,
    name,
    customId,
    requester: {
      fullname: requesterName || '',
      professionalCouncil: crm || '',
      email: requesterEmail || '',
    },
    cnpj,
  };
  removeUndefined(newStructure);

  return newStructure;
};

export const getCompanies = async () => {
  const response = await firebase.firestore().collection('companies').get();

  if (response?.docs?.length > 0) {
    return response.docs.map((doc) => ({
      id: doc.id,
      ...doc?.data(),
    }));
  }

  return null;
};

export const createCompany = async (newCompany) => {
  const dataToSave = convertStructure(newCompany);

  // FIXME: add a better validator and include error to show in the screen
  if (dataToSave.name === undefined) {
    return null;
  }
  dataToSave.createdBy = firebase.auth().currentUser.uid;
  dataToSave.createdAt = firebase.firestore.Timestamp.now();

  try {
    const response = await firebase.firestore().collection('companies').add(dataToSave);

    const result = await response?.get();
    if (result) {
      return {
        id: result.id,
        ...newCompany,
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error creating company', error);
  }
  return null;
};

export const updateCompany = async (id, newData) => {
  const dataToSave = convertStructure(newData);
  // FIXME: add a better validator and include error to show in the screen
  if (dataToSave.name === '') {
    return null;
  }

  dataToSave.updatedBy = firebase.auth().currentUser.uid;
  dataToSave.updatedAt = firebase.firestore.Timestamp.now();

  try {
    await firebase.firestore().collection('companies').doc(id).set(dataToSave, { merge: true });
    return newData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error during updateCompany', error);
  }
  return null;
};

export const deleteCompany = async (id) => {
  if (typeof id !== 'string') {
    return false;
  }

  try {
    const response = await firebase.firestore().collection('companies').doc(id).delete();
    if (response) {
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return false;
};

export const updateAllowedCompanies = async (companyId, changedSamplers) => {
  try {
    const promisesSamplers = changedSamplers.map(async (user) => {
      const userRef = firebase.firestore().collection('users').doc(`${user.id}`);
      if (user.value === true) {
        await userRef.update({
          allowedCompanies: firebase.firestore.FieldValue.arrayUnion(`${companyId}`)
        });
      } else {
        await userRef.update({
          allowedCompanies: firebase.firestore.FieldValue.arrayRemove(`${companyId}`)
        });
      }
    });
    await Promise.all(promisesSamplers);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return false;
};

export const getSamplers = async () => {
  try {
    const token = await firebase.auth().currentUser.getIdToken();
    return await requests.get(`/samplersUsers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return null;
};
