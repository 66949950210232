import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';

const Search = ({ value, onChange, ...props }) => {
  const classes = {};

  const onClickClose = useCallback(() => onChange(''), [onChange]);

  const inputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: value?.length > 0 && (
        <IconButton position="end" size="small" onClick={onClickClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
    }),
    [value, onClickClose],
  );

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <TextField
      aria-label="buscar"
      className={classes.margin}
      id="input-search"
      InputProps={inputProps}
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Search;
