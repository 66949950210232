import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/ButtonBase';

import styles from './styles';

function Button({ children, icon, ...props }) {
  const classes = styles();
  const IconComponent = icon;

  return (
    <MuiButton {...props} classes={{ root: classes.root }}>
      {icon && <IconComponent />}
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default Button;
