import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { urls } from './utils/constants';

const PrivateRoute = ({ children, component, ...props }) => {
  const isAuthenticated = useSelector((state) => state.uid !== '' && state.uid !== undefined);
  const isLoading = useSelector((state) => state.isLoading);

  const Component = children || component;

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isAuthenticated) {
    return <Route exact path={urls.about.path} component={Component} {...props} />;
  }

  return <Redirect to={urls.signin.path} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.node.isRequired,
};

export default PrivateRoute;
