import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 10,
  },
  autocomplete: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    marginRight: 170,
  },
  'import-button': {
    color: theme.palette.custom.mineShaft,
  },
  'import-button-disabled': {
    color: theme.palette.primary.darker,
  },
  uploadButton: {
    marginRight: 12,
  },
  link: {
    margin: 16,
    padding: 8,
    width: 'fit-content',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));
